import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api`;

type SliceState = {
  selectedColumns: any;
  formValues: any;
  searchResults: any;
  selectedIds: any;
  isLoading: boolean;
  batchJobName: string;
  totalRecords: number;
  sortColumn: string;
  crashNumbers: string;
};

const searchSlice = createSlice({
  name: "searchSlice",
  initialState: {
    totalRecords: 0,
    selectedColumns: {
      FINPROJ: true,
      CONTO: true,
      STRCT_NUM_ID: true,
      BUS_AREA_NAME: false,
      DOCUMGRP_DESC: true,
      DOCUMTYPE_DESC: true,
      ABSTRACT: false,
      DOC_DT: false,
      AUTHOR_ID: false,
      TYPIST_ID: false,
      FDOT_PUBLISHED: false,
      SENSITIVE_DOC: false,
      XMPT_PUB_REC_CD: false,
      FDOT_FILE_SIZE: false,
    },
    sortColumn: "DOCNUM",
    formValues: {
      financialProjectNumber: "",
      contractNumber: "",
      structureNumber: "",
      businessArea: "",
      documentGroup: "",
      documentType: "",
      description: "",
      documentDate: "",
      author: "",
      typist: "",
      published: "",
      sensitive: "",
      exempt: "",
    },
    searchResults: [],
    selectedIds: {},
    crashNumbers: "",
    isLoading: true,
    batchJobName: "",
  } as SliceState,
  reducers: {
    setCrashNumbers: (state: SliceState, action: PayloadAction<any>) => {
      state.crashNumbers = action.payload;
      return state;
    },
    setFormValues: (state: SliceState, action: PayloadAction<any>) => {
      state.formValues = action.payload;
      return state;
    },
    setSelectedColumns: (state: SliceState, action: PayloadAction<any>) => {
      state.selectedColumns = action.payload;
      return state;
    },
    setSortColumn: (state: SliceState, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
      return state;
    },
    setTotalRecords: (state: SliceState, action: PayloadAction<any>) => {
      state.totalRecords = action.payload;
      return state;
    },
    setSearchResults: (state: SliceState, action: PayloadAction<any>) => {
      state.searchResults = action.payload;
      state.isLoading = false;
      return state;
    },
    setSelectedIds: (state: SliceState, action: PayloadAction<any>) => {
      state.selectedIds = action.payload;
      return state;
    },
    setBatchJobName: (state: SliceState, action: PayloadAction<any>) => {
      state.batchJobName = action.payload;
      return state;
    },
    setIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
    clearValues: (state: SliceState, action: PayloadAction<any>) => {
      state.formValues = {
        financialProjectNumber: "",
        contractNumber: "",
        structureNumber: "",
        businessArea: "",
        documentGroup: "",
        documentType: "",
        description: "",
        documentDate: "",
        author: "",
        typist: "",
        published: "",
        sensitive: "",
        exempt: "",
      };
      state.crashNumbers = "";
      return state;
    },
  },
});

export const {
  setFormValues,
  setSelectedColumns,
  setSelectedIds,
  setSearchResults,
  clearValues,
  setBatchJobName,
  setIsLoading,
  setTotalRecords,
  setSortColumn,
  setCrashNumbers,
} = searchSlice.actions;
export default searchSlice.reducer;

export const search =
  (
    searchCriteria: any,
    pageSize: number,
    pageNumber: number,
    properties: string[],
    sortOptions: any
  ) =>
  async (dispatch: any) => {
    console.log("searchCriteria", searchCriteria);

    const response = await axios.post(baseUrl + "/search", {
      SearchCriteria: searchCriteria,
      FormName: "EXPORT_QBE",
      PageSize: pageSize,
      PageNumber: pageNumber,
      MaxResults: 100000,
      SelectProperties: properties,
      OrderByProperties: sortOptions,
    });

    dispatch(setTotalRecords(response.data.TotalResults));
    dispatch(setSearchResults(response.data.Results));
  };

export const exportAllSearch =
  (searchCriteria: any) => async (dispatch: any) => {
    const pageSize = 1000; // Number of records to fetch per page
    const selectProperties = ["FDOT_FILE_SIZE"]; // Properties to select
    const orderByProperties = { DOCNUM: true }; // Properties to order by
    let pageNumber = 1; // Initial page number
    let totalResults = 0; // Total number of results
    let allResults: any = []; // Array to store all results

    while (true) {
      const response = await axios.post(baseUrl + "/search", {
        SearchCriteria: searchCriteria,
        FormName: "EXPORT_QBE",
        PageSize: pageSize,
        MaxResults: 100000, // Request only the number of results per page
        PageNumber: pageNumber,
        SelectProperties: selectProperties,
        OrderByProperties: orderByProperties,
      });

      const results = response.data.Results;
      allResults = allResults.concat(results); // Append new results to the existing ones

      if (results.length < pageSize) {
        // If fewer results were returned than the requested page size, it means we have fetched all records
        totalResults = allResults.length;
        break;
      }

      pageNumber++;
    }

    dispatch(setTotalRecords(totalResults));
    dispatch(setSearchResults(allResults));

    const selectedIds: any = {};
    for (let result of allResults) {
      selectedIds[result.Id] = true;
    }

    dispatch(setSelectedIds(selectedIds));
  };

export const exportBatch =
  (exportOptions: any, callback: () => void) => async (dispatch: any) => {
    try {
      await axios.post(baseUrl + "/batchExport", exportOptions);
      if (callback) {
        callback();
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.response.data);
    }
  };
