import React from "react";
import ReactLoading, { LoadingType } from "react-loading";

export interface IProps {
  type?: LoadingType;
  width?: number;
  height?: number;
}

export const LoadingImage: React.FC<IProps> = (props: IProps) => {
  const defaultWidth = 200;
  const defaultHeight = 400;

  const outerStyle: React.CSSProperties = {
    padding: "30px",
  };

  const innerStyle: React.CSSProperties = {
    margin: "0 auto",
    width: props.width ? props.width : defaultWidth,
  };

  return (
    <div style={outerStyle}>
      <div style={innerStyle}>
        <ReactLoading
          type={props.type ? props.type : "bars"}
          color={"white"}
          height={props.height ? props.height : defaultHeight}
          width={props.width ? props.width : defaultWidth}
          delay={300}
        />
      </div>
    </div>
  );
};
