import React, { useEffect, CSSProperties } from "react";
import { fetchConfig } from "../../redux/slices/ConfigSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { LoadingImage } from "../common/LoadingImage";
import Header from "../Header";
import Footer from "../Footer";
import { initializeApp } from "../../utils/Initializer";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import AnonymousRoutes from "./AnonymousRoutes";
import { useGetUserQuery } from "../../redux/slices/apis/UserApi";

const hiddenButScreenReaderAccessibleStyle: CSSProperties = {
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
};

const Routes: React.FC = () => {
  const dispatch = useDispatch();

  const { loadingConfig, config } = useSelector(
    (state: RootState) => state.configReducer
  );

  const { data: user } = useGetUserQuery();

  useEffect(() => {
    if (user && user.userId) {
      dispatch(fetchConfig() as any);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (config && user) {
      initializeApp(config, user.userId);
    }
  }, [config, user]);

  if (loadingConfig) {
    return <LoadingImage></LoadingImage>;
  }

  return (
    <>
      <a href="#maincontent" style={hiddenButScreenReaderAccessibleStyle}>
        Skip to main content
      </a>
      <Header></Header>
      <div id="maincontent" style={{ paddingBottom: "100px" }}>
        <div className="container-fluid">
          <div className="row">
            <div role="main" className="col">
              {user && user.userId ? (
                <AuthenticatedRoutes></AuthenticatedRoutes>
              ) : (
                <AnonymousRoutes></AnonymousRoutes>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Routes;
