import { IDocumentGroup } from "./../../../../../shared/models/IDocumentGroups";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IBusinessArea } from "../../../../../shared/models/IBusinessArea";
import { IDocumentType } from "../../../../../shared/models/IDocumentType";

// Define a service using a base URL and expected endpoints
export const edmsApi = createApi({
  reducerPath: "edmsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: fdot.process.env.BACKEND_SERVER_HOST,
    credentials: "include",
  }),
  tagTypes: ["BusinessAreas", "DocumentGroups", "DocumentTypes", "Libraries"],
  endpoints: (builder) => ({
    getBusinessAreas: builder.query<IBusinessArea[], void>({
      query: () => `api/businessAreas`,
      providesTags: ["BusinessAreas"],
    }),
    getDocumentGroups: builder.query<IDocumentGroup[], void>({
      query: () => `api/documentGroups`,
      providesTags: ["DocumentGroups"],
    }),
    getDocumentTypes: builder.query<IDocumentType[], void>({
      query: () => `api/documentTypes`,
      providesTags: ["DocumentTypes"],
    }),
    getLibraries: builder.query<string[], void>({
      query: () => `api/libraries`,
      providesTags: ["Libraries"],
    }),
  }),
});

export const {
  useGetBusinessAreasQuery,
  useGetDocumentGroupsQuery,
  useGetDocumentTypesQuery,
  useGetLibrariesQuery,
} = edmsApi;
