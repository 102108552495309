import React, { CSSProperties } from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IHelpPopoverProps {
  id: string;
  header?: string;
  body: string;
  topMargin?: string;
}

export const HelpPopover: React.FC<IHelpPopoverProps> = (
  props: IHelpPopoverProps
) => {
  const popoverStyle: CSSProperties = {
    //marginRight: "6px",
    whiteSpace: "pre-line",
  };

  const iconStyle: CSSProperties = {
    marginRight: "2px",
    verticalAlign: "middle",
  };

  const getIcon = (): JSX.Element => {
    return (
      <button
        type="button"
        id={props.id}
        name={props.id}
        aria-label={props.header}
        style={{
          backgroundColor: "transparent",
          borderColor: "transparent",
          borderWidth: 0,
          marginTop: props.topMargin,
        }}
      >
        <FontAwesomeIcon
          size="lg"
          icon={"question-circle"}
          className="text-primary"
          style={iconStyle}
        ></FontAwesomeIcon>
      </button>
    );
  };
  const getTrigger = (): JSX.Element => {
    return <></>;
  };

  return (
    <React.Fragment>
      <span className="sr-only">{props.body} </span>
      {getIcon()}
      {getTrigger()}
      <UncontrolledPopover
        trigger={"legacy"}
        placement="bottom"
        target={props.id}
        style={popoverStyle}
      >
        {props.header ? (
          <PopoverHeader style={popoverStyle}>{props.header}</PopoverHeader>
        ) : (
          <></>
        )}
        <PopoverBody style={popoverStyle}>
          <span dangerouslySetInnerHTML={{ __html: props.body as any }}></span>
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>
  );
};
