import React, { Suspense } from "react";
import Loading from "react-loading";
import { Routes, Route } from "react-router-dom";
import Export from "../search/Export";
import SafetySearchForm from "../search/SafetySearchForm";

const NotAuthorized = React.lazy(() => import("../NotAuthorized"));
const NoMatch = React.lazy(() => import("../NoMatch"));
const LoginFailure = React.lazy(() => import("../LoginFailure"));
const Login = React.lazy(() => import("../Login"));

const AnonymousRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loading></Loading>}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/SafetySearch" element={<SafetySearchForm />} />
        <Route path="/NotAuthorized" element={<NotAuthorized />} />
        <Route path="/Export" element={<Export />} />
        <Route path="/LoginFailure" element={<LoginFailure />} />

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};

export default AnonymousRoutes;
