import React, { CSSProperties } from "react";

const Footer = () => {
  const footerStyles: CSSProperties = {
    backgroundColor: "#EDF1F5",
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-md-center">
        <nav className="navbar fixed-bottom navbar-light" style={footerStyles}>
          <div className="col text-center">
            EEDMS Batch Exporter
            <br />
            Report technical problems to the&nbsp;
            <a
              href="mailto:FDOT.ServiceDesk@dot.state.fl.us?subject=EEDMS%20Batch%20Exporter%20-%20Assistance%20Needed&body=I%20am%20requesting%20assistance%20with%20EEDMS%20Batch%20Exporter,%20please%20issue%20a%20Service%20Desk%20Ticket."
              title="Use this link to E-Mail concerns, problems, and/or comments"
            >
              Service Desk
            </a>
            &nbsp;at 1-866-955-4357
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="Web Policies and Notices (Opens new browser window)"
              href="https://www.fdot.gov/agencyresources/webpoliciesandnotices.shtm"
            >
              Web Policies and Notices
            </a>
            &nbsp; - &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fdot.gov/agencyresources/notices/accessibility.shtm"
              title="Accessibility Statement  (Opens new browser window)"
            >
              Accessibility Statement
            </a>
            &nbsp; - &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://myflorida.com"
              title="My Florida"
            >
              My Florida
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Footer;
