import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAuthenticateResult } from "../../../../../shared/models/IAuthenticateResult";

console.log(fdot.process.env.BACKEND_SERVER_HOST);
// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: fdot.process.env.BACKEND_SERVER_HOST,
    credentials: "include",
  }),
  tagTypes: ["User", "AdUser"],
  endpoints: (builder) => ({
    getUser: builder.query<IAuthenticateResult, void>({
      query: () => `api/user`,
      providesTags: ["User"],
    }),
    getAdUser: builder.query<IAuthenticateResult, void>({
      query: () => `api/v1/user`,
      providesTags: ["AdUser"],
    }),
    authenticate: builder.mutation<
      IAuthenticateResult,
      { userId: string; password: string; library: string }
    >({
      query: ({ userId, password, library }) => ({
        url: `api/authenticate`,
        method: "POST",
        body: { userId, password, library },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useGetUserQuery, useAuthenticateMutation, useGetAdUserQuery } =
  userApi;
