import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faSun,
  faMoon,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
// If you need any regular icons use something like this to import:
// import { faSquare } from '@fortawesome/free-regular-svg-icons';

export const initializeIcons = () => {
  library.add(faExclamationTriangle);
  library.add(faSun);
  library.add(faMoon);
  library.add(faQuestionCircle);
};
