import { edmsApi } from "./slices/apis/EdmsApi";
import { userApi } from "./slices/apis/UserApi";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "./RootReducer";
import thunkMiddleware from "redux-thunk";
//const middleware = [...getDefaultMiddleware(), logger];

const store = configureStore({
  reducer: rootReducer,
  devTools: fdot.process.env.APP_ENVIRONMENT === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(thunkMiddleware)
      .concat(logger)
      .concat(userApi.middleware)
      .concat(edmsApi.middleware),
});

if (fdot.process.env.APP_ENVIRONMENT === "development" && (module as any).hot) {
  (module as any).hot.accept("./RootReducer", () => {
    const newRootReducer = require("./RootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
