import React from "react";
import { useNavigate } from "react-router";
import { CustomCard } from "../common/CustomCard";
import { setCrashNumbers, setSortColumn } from "../../redux/slices/SearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { toast } from "react-toastify";

const SafetySearchForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sortColumn, crashNumbers } = useSelector(
    (state: RootState) => state.searchReducer
  );

  const search = () => {
    if (crashNumbers) {
      navigate("/SearchResults");
    } else {
      toast.error("Please enter crash numbers");
    }
  };

  return (
    <div className="container">
      <CustomCard headerText="Safety Search Criteria">
        <div className="row">
          <div className="col">
            <label htmlFor="CrashNumbers" className="font-weight-bold">
              Enter Crash Numbers:
            </label>
          </div>
        </div>
        <div className="row">
          <textarea
            name="CrashNumbers"
            id="CrashNumbers"
            value={crashNumbers}
            onChange={(e) => dispatch(setCrashNumbers(e.target.value))}
            rows={15}
            className="form-control"
          ></textarea>
        </div>
        <div className="row">
          <div className="col-2">
            <label htmlFor="SortOption" className="font-weight-bold">
              Sort By:
            </label>
          </div>
          <div className="col-4">
            <select
              className="form-control"
              id="SortOption"
              value={sortColumn}
              onChange={(e) => dispatch(setSortColumn(e.target.value))}
            >
              <option value="CARNUM">Crash Number</option>
              <option value="CALYEAR">Crash Year</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button className="btn btn-primary" onClick={search}>
              Search
            </button>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default SafetySearchForm;
