import React, { useState, CSSProperties, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  useGetAdUserQuery,
  useGetUserQuery,
} from "../redux/slices/apis/UserApi";

const Header = () => {
  const headerTextStyle: CSSProperties = {
    color: "white",
    padding: "10px",
  };

  const brandStyle: CSSProperties = {
    paddingRight: 20,
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const { data: user } = useGetUserQuery();
  const [isTestEnv, setIsTestEnv] = useState(false);
  const { data: adUser, isLoading } = useGetAdUserQuery();

  useEffect(() => {
    if (fdot.process.env.APP_ENVIRONMENT !== "production") {
      setIsTestEnv(true);
    }
  }, []);

  useEffect(() => {
    console.log("useEffect: adUser: ", adUser);
    console.log("useEffect: isLoading: ", isLoading);
    if (!isLoading && !adUser) {
      console.log("Redirecting to login");
      window.location.href = `${fdot.process.env.BACKEND_SERVER_HOST}/api/login`;
    }
  }, [isLoading, adUser]);

  //   const externalUser = user && user?.external;
  const toggle = () => setIsExpanded(!isExpanded);

  const getLoginLink = () => {
    if ((user && user.userId) || adUser) {
      return null;
    }
    console.log("getLoginLink");
    //window.location.href = `${fdot.process.env.BACKEND_SERVER_HOST}/api/login`;
    return (
      <Link
        className="nav-link"
        to="/login"
        style={{ ...headerTextStyle, position: "relative", bottom: "-1px" }}
      >
        Login
      </Link>
    );
  };

  const getLoggedInUserDisplay = () => {
    if (user === undefined) {
      return null;
    }

    const backendHost = fdot.process.env.BACKEND_SERVER_HOST;

    return user && user.userId ? (
      <>
        <span
          className="d-none d-xl-block"
          style={{ ...headerTextStyle, position: "relative", bottom: "-2px" }}
        >
          {user.userId.toUpperCase()} (Library:&nbsp;
          {user.library.toUpperCase()})
        </span>
      </>
    ) : (
      ""
    );
  };

  const getSignOutLink = () => {
    if (user === undefined) {
      return null;
    }

    const backendHost = fdot.process.env.BACKEND_SERVER_HOST;

    return user && user.userId ? (
      <>
        <button
          className="nav-link btn-link btn"
          style={headerTextStyle}
          title="Sign Out"
          onClick={() => {
            localStorage.setItem("signOut", "true");
            window.location.href = `${backendHost}/logout`;
          }}
        >
          Sign Out
        </button>
      </>
    ) : (
      ""
    );
  };

  const navbarStyles: CSSProperties = {
    backgroundColor: "#33557C",
  };

  const getNavbar = () => {
    return (
      <div>
        <Navbar dark expand="md" style={navbarStyles}>
          <NavbarBrand style={brandStyle}>
            <img
              src="https://webincludes.dot.state.fl.us/images/FDOTLogowhitetranssm.png"
              alt="fdot logo"
            />
            &nbsp;&nbsp;
            <Link
              className="header-link"
              to={user && user.userId ? "/search" : "/login"}
              title="EEDMS Batch Exporter"
              style={{ color: "white", position: "relative", bottom: "-2px" }}
            >
              EEDMS Batch Exporter
            </Link>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isExpanded} navbar>
            <Nav navbar>{/* {getFeatureOptions()} */}</Nav>
            <Nav className="ml-auto">
              <NavItem>{getLoggedInUserDisplay()}</NavItem>
              <NavItem>{getSignOutLink()}</NavItem>
              <NavItem>{getLoginLink()}</NavItem>
              <NavItem>
                <a
                  className="nav-link"
                  href="./help.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    ...headerTextStyle,
                    position: "relative",
                    bottom: "-1px",
                  }}
                >
                  Help
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {isTestEnv && (
          <div className="container-fluid bg-warning">
            <div className="row d-flex justify-content-center font-weight-bold">
              Warning: This is a Test Environment!
            </div>
          </div>
        )}
      </div>
    );
  };

  return getNavbar();
};

export default Header;
