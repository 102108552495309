import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { exportBatch, setBatchJobName } from "../../redux/slices/SearchSlice";
import { CustomCard } from "../common/CustomCard";
import { useGetUserQuery } from "../../redux/slices/apis/UserApi";
import { HelpPopover } from "../common/HelpPopover";
import { toast } from "react-toastify";

const Export: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedIds, searchResults, batchJobName, crashNumbers } =
    useSelector((state: any) => state.searchReducer);
  const { data: user } = useGetUserQuery();

  const [selectedColumns, setSelectedColumns] = useState({
    FINPROJ: true,
    CONTO: true,
    STRCT_NUM_ID: true,
    BUS_AREA_NAME: false,
    DOCUMGRP_DESC: true,
    DOCUMTYPE_DESC: true,
    ABSTRACT: false,
    DOC_DT: false,
    AUTHOR_ID: false,
    TYPIST_ID: false,
    FDOT_PUBLISHED: false,
    SENSITIVE_DOC: false,
    XMPT_PUB_REC_CD: false,
    FDOT_FILE_SIZE: false,
  } as any);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSearchSize = (selectedIds: any, searchResults: any) => {
    let size = 0;
    console.log("Selected Ids: ", Object.keys(selectedIds));
    console.log("Selected Id Lengths: ", Object.keys(selectedIds).length);
    console.log("Search Results Length: ", searchResults.length);

    Object.keys(selectedIds).forEach((id) => {
      const result = searchResults.find((result: any) => result.Id == id);
      if (result) {
        size += parseInt(result.Properties.FDOT_FILE_SIZE);
      }
    });

    console.log("Size: ", size);
    return Math.ceil(size);
  };

  const submitBatchJob = () => {
    console.log("Submit Batch Job");
    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
    const notValid = invalidChars.test(batchJobName);

    if (notValid) {
      toast.error(
        'Batch Job Name cannot contain any of the following characters: < > : " / \\ | ? *'
      );
      return;
    }

    const parameters: any = {
      BatchJobName: batchJobName.replace(/ /g, "_"),
      BatchProcessId: 2,
      ColumnsToDisplay: Object.keys(selectedColumns).filter((s) => {
        return selectedColumns[s] === true;
      }),
      DeferredSize: 600,
      ExportTargetDir: `${user?.exportLocation}${
        user?.userId
      }\\${batchJobName.replace(/ /g, "_")}\\`,
      EmailAddress: `${user?.userId}@dot.state.fl.us`,
      TotalSizeInKb:
        getSearchSize(selectedIds, searchResults).toString() !== "NaN"
          ? getSearchSize(selectedIds, searchResults).toString()
          : "1000",
      //DeferredStartTime: "20:00",
      //CleanupFrequency: "7",
      SortBy: "DOCNUM",
      SelectedDocNumbers: Object.keys(selectedIds),
    };

    const authorIndex = parameters.ColumnsToDisplay.indexOf("AUTHOR_ID");
    if (authorIndex > -1) {
      parameters.ColumnsToDisplay[authorIndex] = "AUTHOR";
    }
    if (parameters.ColumnsToDisplay.indexOf("TYPIST_ID") > -1) {
      parameters.ColumnsToDisplay[
        parameters.ColumnsToDisplay.indexOf("TYPIST_ID")
      ] = "TYPIST";
    }

    console.log(parameters);
    //navigate("/ExportComplete");

    dispatch(
      exportBatch(parameters, () => {
        navigate("/ExportComplete");
      }) as any
    );
  };

  const selectAll = () => {
    setSelectedColumns({
      FINPROJ: true,
      CONTO: true,
      STRCT_NUM_ID: true,
      BUS_AREA_NAME: true,
      DOCUMGRP_DESC: true,
      DOCUMTYPE_DESC: true,
      ABSTRACT: true,
      DOC_DT: true,
      AUTHOR_ID: true,
      TYPIST_ID: true,
      FDOT_PUBLISHED: true,
      SENSITIVE_DOC: true,
      XMPT_PUB_REC_CD: true,
      FDOT_FILE_SIZE: true,
    });
  };

  return (
    <div className="container">
      <CustomCard headerText="Confirm Batch Export">
        <div className="row">
          <div className="col">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                crashNumbers ? navigate("/SafetySearch") : navigate("/search");
              }}
            >
              Revise Search
            </button>
            <HelpPopover
              id="reviseSearchHelpPopover"
              header="Revise Search"
              body={`The <em>Revise Search</em> navigation button will return you to the initial search page so you can revise your search criteria.  <b>Note</b> – Previously selected documents will <em>not be remembered</em>.`}
            />
          </div>
        </div>
        <div className="font-weight-bold">
          <div className="row text-center">
            <div className="col">
              You have selected {Object.keys(selectedIds).length} Documents for
              export.
            </div>
          </div>
          {!crashNumbers && (
            <div className="row">
              <div className="col">
                The total size of these Documents is{" "}
                {(getSearchSize(selectedIds, searchResults) / 1024).toFixed(2)}{" "}
                MB.
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              Please enter a name for your export batch job:
              <HelpPopover
                id="dirHelpPopover"
                header="Confirm Batch Export"
                body={`On this page you will enter a <em>Name</em> for your export job, <em>max of 30 characters, only letters, numbers, dashes, and spaces are allowed in the Name</em>. The documents you selected are copied to a directory with this <em>Name</em>.  Any illegal characters will be changed automatically OR you will receive a pop-up warning indicating the invalid character that must be changed. 

                The attributes from your selection criteria will contain a check automatically as indicated by the checked box and will be included in the search results. Use the <em>Select All</em> toggle button to toggle between selecting all or none of the checkboxes. The checked attributes are included in the index page that will accompany the export job. 
                
                If this is a revised search, the original selection is part of the toggle sequence.
              `}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                maxLength={30}
                value={batchJobName}
                onChange={(e) => dispatch(setBatchJobName(e.target.value))}
              ></input>
            </div>
          </div>
          <CustomCard headerText="Export Location">
            <div className="row">
              <div className="col">
                {user?.exportLocation}
                {user?.userId}\{batchJobName.replace(/ /g, "_")}
              </div>
            </div>
          </CustomCard>
        </div>
        <br />
        {!crashNumbers && (
          <>
            <div className="row">
              <div className="col">
                <button className="btn btn-primary btn-sm" onClick={selectAll}>
                  Select All
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <table className="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Columns</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              FINPROJ: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.FINPROJ === true}
                        />
                      </td>
                      <td>Financial Project Number</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              CONTO: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.CONTO === true}
                        />
                      </td>
                      <td>Contract Number</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              STRCT_NUM_ID: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.STRCT_NUM_ID === true}
                        />
                      </td>
                      <td>Structure Number</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              BUS_AREA_NAME: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.BUS_AREA_NAME === true}
                        />
                      </td>
                      <td> Business Area</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              DOCUMGRP_DESC: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.DOCUMGRP_DESC === true}
                        />
                      </td>
                      <td> Document Group</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              DOCUMTYPE_DESC: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.DOCUMTYPE_DESC === true}
                        />
                      </td>
                      <td> Document Type</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              ABSTRACT: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.ABSTRACT === true}
                        />
                      </td>
                      <td>Subject / Description</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              DOC_DT: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.DOC_DT === true}
                        />
                      </td>
                      <td> Document Date</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              AUTHOR_ID: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.AUTHOR_ID === true}
                        />
                      </td>
                      <td>Author</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              TYPIST_ID: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.TYPIST_ID === true}
                        />
                      </td>
                      <td>Typist</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              FDOT_PUBLISHED: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.FDOT_PUBLISHED === true}
                        />
                      </td>
                      <td> Published / Unpublished</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              SENSITIVE_DOC: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.SENSITIVE_DOC === true}
                        />
                      </td>
                      <td> Sensitive / Non-Sensitive</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              XMPT_PUB_REC_CD: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.XMPT_PUB_REC_CD === true}
                        />
                      </td>
                      <td> Exempt from Public Records</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelectedColumns({
                              ...selectedColumns,
                              FDOT_FILE_SIZE: e.target.checked,
                            });
                          }}
                          checked={selectedColumns.FDOT_FILE_SIZE === true}
                        />
                      </td>
                      <td> File Size</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary"
              disabled={!batchJobName || batchJobName.length === 0}
              type="button"
              onClick={submitBatchJob}
            >
              Submit Batch Job
            </button>
            <HelpPopover
              id="submitHelpPopover"
              header="Submit Batch Job"
              body={`Use the <em>Submit Batch Job</em> button to submit your request for processing.`}
            />
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default Export;
