import { edmsApi } from "./slices/apis/EdmsApi";
import { combineReducers } from "@reduxjs/toolkit";
import { userApi } from "./slices/apis/UserApi";
import configReducer from "./slices/ConfigSlice";
import searchReducer from "./slices/SearchSlice";

const rootReducer = combineReducers({
  configReducer,
  searchReducer,
  [userApi.reducerPath]: userApi.reducer,
  [edmsApi.reducerPath]: edmsApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
